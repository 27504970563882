import { Image } from '@chakra-ui/react'; 

export const Studio = () => (
  <Image 
    src="/assets/Logo Studio6.0 cobrand UM6P color.svg" 
    alt="Studio" 
    width="auto" 
    height="120px" 
  />
);
